<template>
    <div class="field-text-block" :class="getClass">
        <a class="field-hint" :class="getClass">
            <BaseIcon name="question" :title="fieldText" />
        </a>
    </div>
</template>

<script>
    import {getHint} from '@/utils/textDefs';

    function escapeRegExp(string){
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    export default {
        props:{
            name: String,
            group: String,
            replace: Array,
            targetType: String,
            content: [String, Object]
        },
        computed:{
            fieldText(){
                if(this.replace){
                    let hintText = getHint(this.name, this.group);
                    return hintText.replace(new RegExp(escapeRegExp(`{${this.replace[0]}}`), 'g'), this.replace[1]);
                }
                else if(this.group){
                    return getHint(this.name, this.group);
                }
                else if(this.name){
                    return getHint(this.name);
                }
                else{
                    return this.content || '';
                }
            },
            getClass(){
                if(this.targetType){
                    switch(this.targetType){
                        case "checkbox": {
                            return 'checkbox-hint';
                        }
                        case "row":{
                            return 'flex-hint';
                        }
                        default: {
                            return null;
                        }
                    }
                }
                else{
                    return null;
                }
            }
        }
    }
</script>
<style lang="scss">
    .field-group .field-text-block {
        transform: translate(2em, 1.5em);
    }
    .field-text-block{
        position: absolute;
        z-index: 9;
        right: 0;
        top: 0.8rem;
        &.checkbox-hint{
            display: inline-block;
            z-index: auto;
            right: auto;
            top: 0.2rem;
        }
    }
    .field-hint{
        cursor: pointer;
        position: absolute;
        right: -1.5em;
        &:hover{
            color: var(--mainColor);
        }
    }
</style>