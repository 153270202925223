var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-wrapper" }, [
    _vm.list.length
      ? _c(
          "ul",
          { staticClass: "badge-wrapper" },
          _vm._l(_vm.list, function (item, $index) {
            return _c(
              "li",
              { key: $index, staticClass: "badge" },
              [
                _c("span", { staticClass: "badge-text" }, [
                  _vm._v(_vm._s(item)),
                ]),
                _c("BaseIcon", {
                  staticClass: "action-icon",
                  attrs: { name: "close", size: "8" },
                  on: {
                    click: function ($event) {
                      return _vm.removeItem($index)
                    },
                  },
                }),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model.trim",
          value: _vm.currentInput,
          expression: "currentInput",
          modifiers: { trim: true },
        },
      ],
      ref: "input",
      class: { hasItems: _vm.list.length },
      attrs: {
        required: _vm.isRequired,
        type: _vm.options.type || "text",
        max: _vm.options.max,
        min: _vm.options.min,
        placeholder: 'Type and press "," or enter',
        maxlength: _vm.options.maxItemLength,
      },
      domProps: { value: _vm.currentInput },
      on: {
        keydown: function ($event) {
          return _vm.addItem(_vm.currentInput, $event)
        },
        blur: [
          function ($event) {
            return _vm.addItem(_vm.currentInput, $event)
          },
          function ($event) {
            return _vm.$forceUpdate()
          },
        ],
        input: function ($event) {
          if ($event.target.composing) return
          _vm.currentInput = $event.target.value.trim()
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }