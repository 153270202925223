<template>
    <div>
        <div class="file-input">
            <input ref="fileInput" @change="handleFileInput" type="file" :required="$attrs.required" :accept="$attrs.accept" @click="handleClick">
            <div v-if="file">
                {{file.name}} <small>({{file.size | bytesFilter }})</small>
            </div>
            <div v-else class="t-center"> 
                <BaseIcon name="upload" size="12"/>
                <slot>Select file</slot>
            </div>
        </div>
        <template v-if="showWarnings">
            <p class="field-error" v-if="sizeWarning">Maximum file size is 80MB</p>
            <p class="field-error" v-if="symbolWarning">File name contains forbidden symbols (` \ / : * ? " &lt; > |)</p>
        </template>
    </div>
    
</template>

<script>
import { bytesFilter } from '@/utils/filters'
export default {
    inheritAttrs: false,
    props: {
        showWarnings: Boolean
    },
    data() {
        return {
            file: null,
        }
    },
    computed:{
        sizeWarning(){
            if(!this.showWarnings) return;
            if(!this.file) return false;
            const maxSize = 83886080 //80MB

            if(this.file.size > maxSize){
                this.$refs.fileInput.setCustomValidity("Maximum file size is 80MB");
            }
            return this.file.size > maxSize;
        },
        symbolWarning(){
            if(!this.showWarnings) return;
            if(!this.file) return false;
            const name = this.file.name;

            if(/[`/:*?"<>|]+/.test(name)){  
               this.$refs.fileInput.setCustomValidity('File name contains forbidden symbols');
            }
            return /[`/:*?"<>|]+/.test(name);
        }
    },
    methods: {
        handleFileInput(e){
            this.$refs.fileInput.setCustomValidity("");
            this.file = e.target.files[0];
            this.$emit('update:value', this.file);
            this.$refs.fileInput.onfocus = null;
        },
        handleClick(){
            if(!this.file) return;
            this.$refs.fileInput.value = '';
            this.$refs.fileInput.onfocus = () => {
                this.file = null;
            }

            this.$emit('file-input');
        }
    },  
    filters: {
        bytesFilter
    },
    beforeDestroy(){
        this.$emit('before-destroy');
    }
}
</script>