var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app-logo",
      on: {
        click: function ($event) {
          return _vm.goHome()
        },
      },
    },
    [
      _vm.$theme.isBranded
        ? _c("img", {
            attrs: { src: `/assets/${_vm.theme.id}/img/logo.svg`, alt: "logo" },
          })
        : _c("img", {
            attrs: {
              src: "/assets/default/img/logo.svg",
              alt: "logo",
              width: "139",
              height: "28",
            },
          }),
      _vm.hasLogoText
        ? _c("div", { staticClass: "subtext" }, [
            _vm._v(" Remote management system "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }