var render = function render(_c, _vm) {
  return _c(
    "svg",
    _vm._g(
      {
        staticClass: "base-icon",
        class: [_vm.props.name, _vm.data.class, _vm.data.staticClass],
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: _vm.$options.viewbox(_vm.props.name),
          height: _vm.props.size,
          width: _vm.props.size,
          disabled: _vm.data.attrs.disabled,
          title: _vm.data.attrs.title,
        },
        on: {
          mouseenter: function ($event) {
            return _vm.$options.setTooltip($event, _vm.data)
          },
          mouseleave: function ($event) {
            return _vm.$options.removeTooltip()
          },
          click: function ($event) {
            return _vm.$options.removeTooltip($event, _vm.data)
          },
        },
      },
      _vm.listeners
    ),
    _vm._l(_vm.$options.path(_vm.props.name), function (path, $index) {
      return _c("path", {
        key: $index,
        attrs: {
          d: path,
          stroke: _vm.props.color,
          fill: "none",
          "stroke-width": _vm.props.strokeWidth,
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }