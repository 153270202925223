var render = function render(_c, _vm) {
  return _c("transition", { attrs: { name: "slide", appear: "" } }, [
    _vm.props.errors.message
      ? _c("p", { staticClass: "field-error" }, [
          _vm._v(_vm._s(_vm.props.errors.message)),
        ])
      : _c(
          "ul",
          { staticClass: "error-list" },
          _vm._l(_vm.props.errors, function (value, key) {
            return _c("li", { key: key, staticClass: "field-error" }, [
              _vm._v(" " + _vm._s(value.message || value[0]) + " "),
            ])
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }