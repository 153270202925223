<template>
    <div class="form-field" :class="{'error-input': errorItem, 'table-input': field && field.component === 'TableInput'}">
        <template v-if="field">
            <template v-if="(field.permission ? $user.can(field.permission) : true) && (field.depends ? canShowField : true)">
                <label v-if="!isInlineInput(field)">{{field.label}}</label>
                <template v-if="field.component">
                    <ItemSelect v-if="field.component === 'ItemSelect'" :options="field.options" :value.sync="form[field.key]" :required="field.required"/>
                    <BaseAlert v-if="field.component === 'BaseAlert'" :icon="field.icon">{{field.value(form[field.updateOnKey])}}</BaseAlert>
                    <ListInput v-if="field.component === 'ListInput'" :options="field.options" :value.sync="form[field.key]" :required="field.required"/>
                    <TableInput v-if="field.component === 'TableInput'" :options="field.options" :value.sync="form[field.key]"/>
                </template>
                <template v-else-if="field.options">
                    <select v-model="form[field.key]" :required="field.required">
                        <option :value="undefined" selected disabled>Select {{field.label}}</option>
                        <option v-for="(option, $index) in selectOptions" :key="$index" :value="option.hasOwnProperty('value') ? option.value : option" :title="option.hint">
                            {{option.name || option | optionFormatter(field)}}
                        </option>
                    </select>

                    <div v-if="field.optionDescriptionBlock && form[field.key]">
                        <small class="option-description form-description">{{selectOptions.find(option => option.value == form[field.key]).hint}}</small>
                    </div>
                </template>
                <template v-else>
                   
                    <PassInput v-if="field.type === 'password'" :class="{'flex-row v-center' : field.generate}" ref="passwordInput">
                        <input :type="field.type" :required="field.required" v-model="form[field.key]" :class="{'mr-05' : field.generate}"
                            :minlength="field.minlength" 
                            :maxlength="field.maxlength"
                            :id="field.key">
                            <template v-if="field.generate">
                                <button type="button" @click="generatePassword">Generate</button>
                            </template>
                    </PassInput>

                    <FileInput v-else-if="field.type === 'file'" :value.sync="form[field.key]" :required="field.required" :accept="field.accept" :error="errorItem"/>

                    <template v-else-if="field.type === 'textarea'">
                       <textarea :type="field.type" :required="field.required" v-model="form[field.key]"
                            :minlength="field.minlength" 
                            :maxlength="field.maxlength"
                            :id="field.key"
                            :placeholder="placeholder"
                            ></textarea>
                    </template>

                    <template v-else>
                        <!-- @paste="onPaste($event, field)" -->
                            <input :type="field.type" :required="field.required" v-model="form[field.key]"
                            :min="minValue" 
                            :max="maxValue"
                            :step="field.step"
                            :minlength="field.minlength" 
                            :maxlength="field.maxlength"
                            v-mask="field.mask"
                            :id="field.key"
                            :placeholder="placeholder"
                            :pattern="field.pattern"
                            :disabled="field.disabled"
                            :class="field.class"
                            :true-value="field.trueValue || true" 
                            :false-value="field.falseValue || false"
                            >
                    </template>

                    <label :for="field.key" v-if="isInlineInput(field)">{{field.label}}</label>
                </template>
                <FieldHint v-if="field.hint" :group="field.hint.group || formSchema.description" :name="field.hint.name" :target-type="field.hint.target" :content="field.hint" />
            </template>
        </template>
        <slot v-else></slot>
        <transition v-if="errorItem && (field && field.permission ? $user.can(field.permission) : true) && (field && field.depends ? canShowField : true)" name="slide" appear>
            <ul class="field-error-list">
                <li class="field-error" v-for="(error, $index) in errorItem" :key="$index">{{error.message}}</li>
            </ul>
        </transition>
    </div>

</template>

<script>
    import FormMixin from '@/mixins/formMixin';
    
    import FieldHint from '@/components/FieldHint';
    import PassInput from '@/components/PassInput';
    import FileInput from '@/components/FileInput';
    import ListInput from '@/components/ListInput';
    import TableInput from '@/components/TableInput';

    import {formatCell} from '@/utils/filters'
    import {generateString} from '@/utils';
    
    import mask from '@/directives/mask';
    export default {
        components: {
            FieldHint, PassInput, ListInput, FileInput, TableInput,
            ItemSelect: () => import("@/components/ItemSelect.vue"),
        },
        mixins: [FormMixin],
        directives: {mask},
        props: {
            field:  Object,
            error: [Object, Array],
            form: Object,
            formSchema: Object
        },
        data() {
            return {
                selectOptions: []
            }
        },
        async created(){
            if(this.field && this.field.options && !this.field.component){
                if(typeof this.field.options === 'function'){
                    this.selectOptions = await this.field.options(this, this.form);
                }else{
                    this.selectOptions = this.field.options;
                }
            }
        },
        computed:{
            fieldValue(){
                if(this.form && this.field){
                    return this.form[this.field.key];
                }
                return undefined;
            },
            errorItem(){
                if(this.error){
                    if(Array.isArray(this.error))return this.error;
                    return [this.error];
                }  
                return null;
            },
            placeholder(){
                if(typeof this.field.placeholder === 'function'){
                    return this.field.placeholder(this.form);
                }
                return this.field.placeholder;
            },
            canShowField(){
                if(!this.field) return;
                const depends = this.field.depends || [];
                if(Array.isArray(depends)){
                    const values = depends.map(item => {
                        return item.values.includes(this.form[item.key]);
                    });
                    return values.every(value => value === true);
                }
                else if(typeof depends === 'function'){
                    return depends(this.form);
                }
                else{
                    return depends.values.includes(this.form[depends.key]);
                }
            },
            maxValue(){
                if(typeof this.field.max === 'function'){
                    return this.field.max(this.form, this.field.key);
                }
                return this.field.max;
            },
            minValue(){
                if(typeof this.field.min === 'function'){
                    return this.field.min(this.form, this.field.key);
                }
                return this.field.min;
            }
        },
        methods: {
            generatePassword(){
                this.$refs.passwordInput.toggle('password');
                this.$set(this.form, this.field.key, generateString(16));
            },
            isInlineInput(field){
                const inlineTypes = ['checkbox', 'radio'];
                return inlineTypes.includes(field.type);
            },
            onPaste(e, field){
                
                let pasteData = (e.clipboardData || window.clipboardData).getData('text').trim();

                if(this.field && this.field.maxlength && pasteData.length > this.field.maxlength){
                    e.preventDefault();
                    const trimNum = this.field.maxlength - pasteData.length;
                    pasteData = pasteData.slice(0, trimNum);
                }

                // e.target.value = '';
                // e.target.value = pasteData;
                if(!this.form[field.key]){
                    this.$set(this.form, field.key, pasteData)
                }else{
                    this.form[field.key] += pasteData;
                }
            }
        },
        filters:{
            optionFormatter(value, field){
                if(field.formatter){
                    return formatCell(value, field.formatter)
                }
                return value;
            }
        },
        watch:{
            canShowField(newVal, oldVal){
                if(this.form[this.field.key] && oldVal == true && newVal == false){
                    this.$delete(this.form, this.field.key)
                }
            },
            fieldValue: function(newVal, oldVal) {

                if(oldVal && newVal !== oldVal){
                    this.$delete(this.$parent.$data.$_fieldErrors, this.field.key);
                }

                if(this.field && this.field.onChange){
                    this.field.onChange(this, this.form);
                }
            }
        }

    }
</script>
<style lang="scss">
    .field-error{
        font-size: 0.8rem;
        margin-top: .25em;
    }
    .field-error-list{
        max-height: 8ch;
        padding-bottom: 1px;
        overflow: auto;
        max-width: 550px;
    }

    .table-input label{
        margin-left: 0;
        margin-bottom: 0.5em;
        padding-bottom: 0.5em;
        position: static;
        border-bottom: 1px solid var(--borderInput);
        display: block;
    }

    .option-description{
        display: block;
        margin-top: 0.5rem;
        word-break: break-word;
    }
</style>