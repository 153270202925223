var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.rows.length
    ? _c("BaseIcon", {
        staticClass: "action-icon",
        attrs: { title: "Add row", size: "14", name: "plus" },
        on: { click: _vm.addRow },
      })
    : _c(
        "table",
        [
          _c(
            "tr",
            [
              _c("th"),
              _vm._l(_vm.options.fields, function (field) {
                return _c("th", { key: field.key }, [
                  _vm._v(_vm._s(field.title)),
                ])
              }),
            ],
            2
          ),
          _vm._l(_vm.rows, function (row, $index) {
            return _c(
              "tr",
              { key: $index },
              [
                _c(
                  "td",
                  [
                    $index === _vm.rows.length - 1
                      ? _c("BaseIcon", {
                          staticClass: "action-icon",
                          attrs: { title: "Add row", size: "14", name: "plus" },
                          on: { click: _vm.addRow },
                        })
                      : _vm._e(),
                    _c("BaseIcon", {
                      staticClass: "action-icon",
                      attrs: { title: "Remove row", size: "14", name: "minus" },
                      on: {
                        click: function ($event) {
                          return _vm.removeRow($index)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._l(_vm.options.fields, function (field) {
                  return _c("td", { key: field.key }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: row[field.key],
                          expression: "row[field.key]",
                        },
                      ],
                      attrs: {
                        type: "text",
                        required: "",
                        pattern: field.pattern,
                        maxlength: field.maxlength,
                      },
                      domProps: { value: row[field.key] },
                      on: {
                        blur: function ($event) {
                          return _vm.$emit("update:value", _vm.rows)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(row, field.key, $event.target.value)
                        },
                      },
                    }),
                  ])
                }),
              ],
              2
            )
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }