<template>
    <BaseIcon title="Add row" v-if="!rows.length" size="14" name="plus" class="action-icon" @click="addRow"/>
    <table v-else>
        <tr>
            <th></th>
            <th v-for="field in options.fields" :key="field.key">{{field.title}}</th>
        </tr>
        <tr v-for="(row, $index) in rows" :key="$index">
            <td>
                <BaseIcon title="Add row" v-if="$index === rows.length -1" size="14" name="plus" class="action-icon" @click="addRow"/>
                <BaseIcon title="Remove row" size="14" name="minus" class="action-icon" @click="removeRow($index)"/>
            </td>
            <td v-for="field in options.fields" :key="field.key">
                <input type="text" v-model="row[field.key]" required @blur="$emit('update:value', rows)"
                    :pattern="field.pattern"
                    :maxlength="field.maxlength"
                >
            </td>
        </tr>
    </table>
</template>

<script>
export default {
    props: {
        options: Object
    },
    data() {
        return {
            rows: []
        }
    },
    methods:{
         addRow(){
            this.rows.push({});
        },
        removeRow(index){
            this.rows.splice(index, 1);
        },
    }
}
</script>
<style lang="scss" scoped>
table{
    th{
        font-size: 0.75rem;
    }
    th,td{
        padding: 0.2rem;
    }
    td{
        input[type="text"]{
            width: 105px !important;
        }
    }
}

</style>