<template>
    <div class="input-wrapper">
        <ul class="badge-wrapper" v-if="list.length">
            <li class="badge" v-for="(item, $index) in list" :key="$index">
                <span class="badge-text">{{item}}</span>
                <BaseIcon name="close" size="8" class="action-icon" @click="removeItem($index)"/>
            </li>
        </ul>
        <!-- <form ref="form" @submit.prevent="() => {}"> -->
            <input ref="input" :required="isRequired" :type="options.type || 'text'" :max="options.max" :min="options.min" placeholder='Type and press "," or enter' :class="{'hasItems': list.length}" v-model.trim="currentInput" @keydown="addItem(currentInput, $event)" @blur="addItem(currentInput, $event)" :maxlength="options.maxItemLength">
            <!-- <button ref="button" type="submit"></button> -->
        <!-- </form> -->
    </div>
</template>

<script>
    export default {
        props: {
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                currentInput: "",
                list: []
            }
        },
        created(){
            if(this.$attrs.value && this.$attrs.value.length){
                this.list = this.$attrs.value;
            }
        },
        computed:{
            isRequired(){
                if (this.list.length) return false;
                return this.$attrs.required;
            }
        },
        methods:{
            addItem(value, e){
                if(!value) return;
                if(this.list.length === this.options.maxItems) return;
                if(e.type === 'keydown' && !['Enter', ','].includes(e.key)) return;
                e.preventDefault();

                if(this.options?.type === 'number') {
                    value = Number(value);
                }

                const input = this.$refs.input;
                const form = input.form;
                if(form && !input.checkValidity()){
                    const button = form.elements[form.elements.length - 1];
                    button.click();
                    return;
                }

                this.list.push(value);
                this.$emit('update:value', this.list);
                this.currentInput = "";
            },
            removeItem(index){
                this.list.splice(index, 1);
                this.$emit('update:value', this.list);
            }
        }
    }
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.input-wrapper{
    // max-width: 381px;
    @extend .default-input;
    position: relative;
    &:focus-within{
        border-color: var(--mainColor);
    }
    input{
        border: 0;
        
        &:not([readonly]):focus::placeholder{
            opacity: 0.5;
        }

        &.hasItems{
            padding-top: 0.5rem;
        }
    }
}
.badge-wrapper{
    margin: 1em 0.5em 0em 0.5em;;
}
form button{
    display: none;
}
</style>