<template>
    <div class="password-input">
        <a class="password-show" @click="toggle()">
            <BaseIcon class="action-icon" :name="type ? 'visible_off' : 'visible'" size="16" />
        </a>
        <slot></slot>         
    </div>
</template>

<script>
export default {
    data() {
        return {
            type: false
        }
    },
    methods:{
        toggle(value){
            let valueType = value ? value : (this.$slots.default[0].elm.type || this.$slots.default[0].elm.firstElementChild.type);
            if(valueType == 'password'){
                valueType = 'text';
                this.type = true;
            }
            else{
               valueType = 'password';
               this.type = false;
            }
            if(this.$slots.default[0].elm.type){
                this.$slots.default[0].elm.type = valueType;
            }
            else{
                this.$slots.default[0].elm.firstElementChild.type = valueType
            }
        }
    }
}
</script>
<style lang="scss">
    .password-input{
        position: relative;
        input[type='password'],
        input[type='text']{
            padding-left: 2.8em
        }
        > div{
            display: block;
            width: 100%;
        }
        .password-show{
            position: absolute;
            margin-left: .7em;
            top: .8em;
        }
    }
</style>