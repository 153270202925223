var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c(
            "transition-group",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasModals,
                  expression: "hasModals",
                },
              ],
              tag: "div",
              staticClass: "modal-container",
              class: { "minimize-container": _vm.closeContainer },
              attrs: { name: "modal" },
            },
            _vm._l(_vm.modals, function (modal, $index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !modal.minimized,
                      expression: "!modal.minimized",
                    },
                  ],
                  key: modal.id || $index,
                  staticClass: "modal-box",
                  class: { "main-modal": modal.mainModal },
                  attrs: { tabindex: "0" },
                  on: {
                    mousedown: function ($event) {
                      return _vm.setMainModal($index)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      )
                        return null
                      !modal.hideHeaderControls
                        ? _vm.close($index, "keydown")
                        : null
                    },
                  },
                },
                [
                  !modal.hideHeader
                    ? _c(
                        "header",
                        {
                          staticClass: "modal-header",
                          class: { prompt: Boolean(modal.prompt) },
                          on: {
                            mousedown: _vm.dragElement,
                            click: function ($event) {
                              return _vm.focusModal(modal.id)
                            },
                          },
                        },
                        [
                          _c("p", { staticClass: "modal-title" }, [
                            _vm._v(_vm._s(modal.title)),
                          ]),
                          !modal.hideHeaderControls
                            ? _c("div", [
                                !modal.prompt
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "minimize-icon hover-opacity",
                                        on: {
                                          click: function ($event) {
                                            return _vm.minimize($index)
                                          },
                                        },
                                      },
                                      [
                                        _c("BaseIcon", {
                                          attrs: {
                                            name: "minimize",
                                            size: "10",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "span",
                                  {
                                    staticClass: "hover-opacity",
                                    on: {
                                      click: function ($event) {
                                        return _vm.close($index)
                                      },
                                    },
                                  },
                                  [
                                    _c("BaseIcon", {
                                      attrs: { name: "close", size: "10" },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "flex-row" }, [
                    _vm.showSelectBox(
                      modal.selectionKey,
                      modal.hasOwnProperty("selectBox")
                        ? modal.selectBox
                        : false
                    )
                      ? _c(
                          "div",
                          [
                            _c("SelectBox", {
                              ref: "selection",
                              refInFor: true,
                              attrs: {
                                "selection-key": modal.selectionKey,
                                prompt: Boolean(modal.prompt),
                                "modal-id": modal.id,
                              },
                              on: {
                                "toggle-select": function ($event) {
                                  return _vm.hasSelected($event, modal)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "modal-content",
                        class: {
                          "p-0": modal.noPadding,
                          [modal.id]: true,
                          prompt: Boolean(modal.prompt),
                          [modal.component_id]: modal.component_id,
                        },
                        attrs: { tabindex: "0" },
                      },
                      [
                        modal.subtitle
                          ? _c("p", { staticClass: "form-description mb-1" }, [
                              _vm._v(_vm._s(modal.subtitle)),
                            ])
                          : _vm._e(),
                        modal.loadedComponent
                          ? _c(modal.loadedComponent, {
                              tag: "component",
                              attrs: { data: modal.props, schema: modal.form },
                            })
                          : _vm._e(),
                        _vm.showReminderCheckbox(modal.reminderKey)
                          ? _c(
                              "label",
                              {
                                staticClass:
                                  "reminder-checkbox form-description",
                              },
                              [
                                _c("input", { attrs: { type: "checkbox" } }),
                                _vm._v(" Do not show this again "),
                                _c("small", [
                                  _vm._v(
                                    "(can be re-enabled in the table preferences sidebar)"
                                  ),
                                ]),
                                _vm._v(". "),
                              ]
                            )
                          : _vm._e(),
                        modal.prompt
                          ? _c(
                              "div",
                              [
                                modal.input
                                  ? [
                                      _c(
                                        "form",
                                        {
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleConfirm(
                                                modal.confirm,
                                                _vm.$refs.promptInput[0].value
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("FormField", [
                                            _c("label", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  modal.input.label
                                                ),
                                              },
                                            }),
                                            _c("input", {
                                              ref: "promptInput",
                                              refInFor: true,
                                              attrs: {
                                                type:
                                                  modal.input.type || "text",
                                                required: "",
                                              },
                                            }),
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "modal-footer" },
                                            [
                                              _c(
                                                "button",
                                                { attrs: { type: "submit" } },
                                                [_vm._v("Confirm")]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "secondary",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.close()
                                                    },
                                                  },
                                                },
                                                [_vm._v("Cancel")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _c("div", { staticClass: "modal-footer" }, [
                                      _c(
                                        "button",
                                        {
                                          attrs: {
                                            disabled:
                                              modal.selectBox &&
                                              !_vm.selected.length,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleConfirm(
                                                modal,
                                                true
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Confirm")]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "secondary",
                                          on: {
                                            click: function ($event) {
                                              return _vm.close()
                                            },
                                          },
                                        },
                                        [_vm._v("Cancel")]
                                      ),
                                    ]),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
      _vm.modals.length
        ? _c(
            "div",
            { staticClass: "minimized-list" },
            _vm._l(_vm.modals, function (modal, $index) {
              return _c(
                "div",
                {
                  key: modal.id,
                  staticClass: "minimized-item",
                  class: { minimized: modal.minimized },
                },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.minimize($index)
                        },
                      },
                    },
                    [_vm._v(_vm._s(modal.title))]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "close-icon",
                      on: {
                        click: function ($event) {
                          return _vm.minimizeClose($index)
                        },
                      },
                    },
                    [_c("BaseIcon", { attrs: { name: "close", size: "10" } })],
                    1
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }