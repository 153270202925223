var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "password-input" },
    [
      _c(
        "a",
        {
          staticClass: "password-show",
          on: {
            click: function ($event) {
              return _vm.toggle()
            },
          },
        },
        [
          _c("BaseIcon", {
            staticClass: "action-icon",
            attrs: { name: _vm.type ? "visible_off" : "visible", size: "16" },
          }),
        ],
        1
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }