<template functional>
    <transition name="slide" appear>
        <p class="field-error" v-if="props.errors.message">{{props.errors.message}}</p>
        <ul v-else class="error-list">
            <li v-for="(value, key) in props.errors" :key="key" class="field-error">
                {{value.message || value[0]}}
            </li>
        </ul>
    </transition>
</template>

<script>
export default {
    name:'ErrorList',
    props: {
        errors: {
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.error-list{
    max-width: 550px;
    overflow-x: auto;
}
</style>