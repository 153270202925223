var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "file-input" }, [
        _c("input", {
          ref: "fileInput",
          attrs: {
            type: "file",
            required: _vm.$attrs.required,
            accept: _vm.$attrs.accept,
          },
          on: { change: _vm.handleFileInput, click: _vm.handleClick },
        }),
        _vm.file
          ? _c("div", [
              _vm._v(" " + _vm._s(_vm.file.name) + " "),
              _c("small", [
                _vm._v(
                  "(" + _vm._s(_vm._f("bytesFilter")(_vm.file.size)) + ")"
                ),
              ]),
            ])
          : _c(
              "div",
              { staticClass: "t-center" },
              [
                _c("BaseIcon", { attrs: { name: "upload", size: "12" } }),
                _vm._t("default", function () {
                  return [_vm._v("Select file")]
                }),
              ],
              2
            ),
      ]),
      _vm.showWarnings
        ? [
            _vm.sizeWarning
              ? _c("p", { staticClass: "field-error" }, [
                  _vm._v("Maximum file size is 80MB"),
                ])
              : _vm._e(),
            _vm.symbolWarning
              ? _c("p", { staticClass: "field-error" }, [
                  _vm._v(
                    'File name contains forbidden symbols (` \\ / : * ? " < > |)'
                  ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }