<template>
    <div>
        <transition name="fade">
            <div v-show="hasModals" class="modal-container" :class='{"minimize-container": closeContainer}' name="modal" is="transition-group">
                <!-- <transition-group> -->
                <div class="modal-box" tabindex="0" v-for="(modal, $index) in modals" :class="{'main-modal': modal.mainModal}" v-show="!modal.minimized" :key="modal.id || $index" @mousedown="setMainModal($index)" @keydown.esc="!modal.hideHeaderControls ? close($index, 'keydown') : null">
                    
                    <header class="modal-header" :class="{'prompt': Boolean(modal.prompt)}" @mousedown="dragElement" @click="focusModal(modal.id)" v-if="!modal.hideHeader">
                        <p class="modal-title">{{modal.title}}</p>
                        <div v-if="!modal.hideHeaderControls" >
                            <span v-if="!modal.prompt" @click="minimize($index)" class="minimize-icon hover-opacity">
                                <BaseIcon name="minimize" size="10"/>
                            </span>
                            <span @click="close($index)" class="hover-opacity">
                                <BaseIcon name="close" size="10"/>
                            </span>
                        </div>
                    </header>

                    <div class="flex-row">
                        <div v-if="showSelectBox(modal.selectionKey, modal.hasOwnProperty('selectBox') ? modal.selectBox : false)">
                            <SelectBox ref="selection" @toggle-select="hasSelected($event, modal)"  :selection-key="modal.selectionKey" :prompt="Boolean(modal.prompt)" :modal-id="modal.id" />
                        </div>

                        <div class="modal-content" :class="{'p-0': modal.noPadding, [modal.id]: true, 'prompt': Boolean(modal.prompt), [modal.component_id]: modal.component_id}" tabindex="0">
                            <p v-if="modal.subtitle" class="form-description mb-1">{{modal.subtitle}}</p>
                            <component v-if="modal.loadedComponent" :is="modal.loadedComponent" :data="modal.props" :schema="modal.form"/>
                            <label v-if="showReminderCheckbox(modal.reminderKey)" class="reminder-checkbox form-description">
                                <input type="checkbox">
                                Do not show this again <small>(can be re-enabled in the table preferences sidebar)</small>.
                            </label>
                            <div v-if="modal.prompt">
                                <template v-if="modal.input">
                                    <form @submit.prevent="handleConfirm(modal.confirm, $refs.promptInput[0].value)">
                                        <FormField>
                                            <label v-text="modal.input.label"></label>
                                            <input :type="modal.input.type || 'text'" ref="promptInput" required>
                                        </FormField> 
                                        <div class="modal-footer">
                                            <button type="submit">Confirm</button>
                                            <button type="button" class="secondary" @click="close()">Cancel</button>
                                        </div>
                                    </form> 
                                </template>
                                <div class="modal-footer" v-else>
                                    <button @click="handleConfirm(modal, true)" :disabled="modal.selectBox && !selected.length">Confirm</button>
                                    <button class="secondary" @click="close()">Cancel</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- </transition-group> -->
            </div>
        </transition>
        
        <div class="minimized-list" v-if="modals.length">
            <div class="minimized-item" v-for="(modal, $index) in modals" :key="modal.id" :class="{'minimized' :modal.minimized}"  >
                <a @click="minimize($index)">{{modal.title}}</a>
                <a class="close-icon" @click="minimizeClose($index)"><BaseIcon name="close" size="10" /></a>
            </div>
        </div>
        
    </div>
</template>

<script>
import {lazyLoadComponent} from "@/utils" 
import selectionStore from "@/stores/selectionStore";
import FormMixin from '@/mixins/formMixin';
const componentsArray = ['VpnDetails', 'RemoteAccessInfo'];
export default {
    provide:{
        ItemSelect: () => import("@/components/ItemSelect.vue"),
        FieldHint: () => import("@/components/FieldHint.vue"),
        FormDescription: () => import("@/components/FormDescription.vue")
    },
    components:{
        SelectBox: () => import("@/components/SelectBox.vue")
    },
    mixins: [FormMixin],
    data() {
        return {
            selected: [],
            modals: [],
            minimizedModals: [],
            closeContainer: false,
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0
            }
        };
    },
    computed: {
        hasModals(){
            const hasModals = !!this.modals.filter(modal => !modal.minimized).length;

            hasModals
                ? document.body.classList.add('no-scroll')
                : document.body.classList.remove('no-scroll');

            return hasModals;
        }
    },
    methods: {
        focusModal(modal){
            setTimeout(()=>{
                let modalClass = document.querySelector(`.${modal}`);
                if(modalClass){
                    modalClass.focus();
                }
            })
        },
        modalClass(modal){
            return{
                [modal.id]: true,
                'p-0': modal.noPadding
            }
        },
        hasSelected(items, modal){
            this.selected = items;
            if(modal.props){
                this.$set(modal.props, 'selection', items);
            }
        },
        open(options){
            if(!options) return;            
            if(this.closeContainer){
                this.closeContainer = !this.closeContainer;
            }
            if(options.component){
                if(typeof options.component === 'string'){
                    if(options.component.includes('/')){
                        options.loadedComponent = () => lazyLoadComponent(import(`@/components/${options.component}.vue` /* webpackChunkName: "modals" */));
                    }else{
                        options.loadedComponent = () => lazyLoadComponent(import(`@/components/modals/${options.component}.vue` /* webpackChunkName: "modals" */));
                    }
                }else{
                    options.loadedComponent = options.component;
                }
            }
            else if(options.form){
                options.loadedComponent = () => lazyLoadComponent(import("@/components/modals/BaseForm.vue" /* webpackChunkName: "modals" */));
            }

            if(options.selectionKey && !options.prompt && selectionStore[options.selectionKey]){                
                if(Array.isArray(selectionStore[options.selectionKey])){
                    options.props.selection = selectionStore[options.selectionKey]
                }else{
                    options.props.selection = [selectionStore[options.selectionKey]];
                }
            }

            if(!options.id && options.title){
                options.id = removeSymbols(camelize(options.title));
            }
            if(componentsArray.includes(options.component)){
                options.component_id = options.component
            }
                        
            options.minimized = false;
            options.mainModal = true;
            let existIndex = this.modals.findIndex(item => item.id === options.id);

            if(existIndex > -1){
                this.modals.splice(existIndex, 1);
                this.modals.push(options);
            }
            else{
                this.modals.push(options);   
            }

            setTimeout(() =>{
                this.removeMainModal();
                this.focusModal(options.id);
            });
            window.hj('event', `Modal open: ${options.component_id ? options.component_id : options.id}`);
        },
        close(index, keydown){
            if(this.modals[index]?.id === "addVerificationDevice"){
                this.$eventHub.$emit('modal-close');
            }

            if(keydown && this.modals[index]?.id === 'emailPrompt'){
                return;
            }
            if(keydown && document.body.classList.contains('guide-mode')){
                return;
            }
            if(index != null){
                this.modals.splice(index, 1);
            }
            else{
                this.modals.pop();
            }
            if(this.modals.length) {
                this.isOpenModalExist();
                this.focusModal(this.modals.slice(-1).pop()?.id);
            }
        },
        minimizeClose(index){
            this.minimizedModals.splice(index, 1) && this.close(index);
        },
        minimizeCloseAll(){
            this.minimizedModals = [];
        },
        closeAll(){
            this.modals = [];
        },
        confirm(message, options){     
            return new Promise((resolve) => {
                const modalOptions = {
                    title: message,
                    prompt: true,
                    confirm: options?.onConfirm || resolve,
                    // cancel: reject,
                    selectBox: options?.selectBox,
                    selectionKey: options?.selectionKey,
                    subtitle: options?.subtitle,
                    input: options?.input,
                    component: options?.component,
                    props: options?.props,
                    reminderKey: options?.reminderKey
                };

                if(options?.reminderKey){
                    const reminderValue = JSON.parse(localStorage.getItem(options.reminderKey));
                    if(reminderValue) return resolve(true);
                }

                this.open(modalOptions);
            });
        },
        handleConfirm(modal, value){
            if(value && this.$refs?.selection && this.$refs?.selection.length){
                selectionStore.updateStore(this.$refs.selection[0].selectionKey, this.$refs.selection[0].selection);
            }
            if(modal.reminderKey){
                const checkboxInput = document.querySelector('.reminder-checkbox input');
                const checkboxValue = checkboxInput.checked;
                if(checkboxValue){
                    this.$userStorage.set(modal.reminderKey, true);
                }
            }
            return modal.confirm(value), this.close();
        },
        isOpenModalExist(){
            let allMinimizedModals = (currentValue) => currentValue.minimized == true;
            this.closeContainer = this.modals.every(allMinimizedModals);
            if(this.closeContainer){
                document.body.classList.remove('no-scroll');
            }
        },
        minimize(index){
            if(this.modals.length <= 5){
                if(Object.prototype.hasOwnProperty.call(this.modals[index], 'minimized')){
                    this.modals[index].minimized = !this.modals[index].minimized;
                }
                else{
                    this.modals[index].minimized = true
                }
                this.isOpenModalExist();

                if(!this.modals[index].minimized){
                    this.removeMainModal();
                    this.modals[index].mainModal = true;
                    this.focusModal(this.modals[index].id);    
                }
            }
            else{
                this.$snack.show({text: 'You can not minimize more than 5 windows'});
            }
            window.hj('event', 'Action: Minimize');
        },
        minimizeAll(){
            if(!this.modals.length) return;
            this.modals.forEach(modal =>{
                modal.minimized = true; 
            })
        },
        dragElement(event) {
            if(event.target.nodeName === 'svg') return;
            let element = event.target.closest(".modal-box");
            let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            event.preventDefault();
            pos3 = event.clientX;
            pos4 = event.clientY;
            document.onmouseup = closeDragElement;
            document.onmousemove = elementDrag;
            function elementDrag(e) {
                e.preventDefault();
                pos1 = pos3 - e.clientX;
                pos2 = pos4 - e.clientY;
                pos3 = e.clientX;
                pos4 = e.clientY;

                const mouseOutside = e.clientY <= 0 || e.clientX <= 0 || (e.clientX >= window.innerWidth || e.clientY >= window.innerHeight);
                if(mouseOutside) return;

                
                element.style.top = `${(element.offsetTop - pos2)}px`;
                element.style.left = `${(element.offsetLeft - pos1)}px`;
                element.style.position = 'absolute';
            }
            function closeDragElement() {
                document.onmouseup = null;
                document.onmousemove = null;
            }
        },
        setMainModal(index){
            if(this.modals.length === 1) return;
            if(this.modals[index]){
                this.removeMainModal();
                this.modals[index].mainModal = true;
            }
        },
        removeMainModal(){
            this.modals.forEach((el) => {
                el.mainModal = false;
            });
            document.querySelector('.modal-content').focus();
        },
        showSelectBox(key, condition){
            if(condition && selectionStore[key]?.length)
                return true;
            return false
        },
        showReminderCheckbox(reminderKey){
            if(!reminderKey) return false;

            const reminderValue = this.$userStorage.get(reminderKey);
            return !reminderValue;
        }
            
    }
}
function camelize(str) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()).replace(/[^a-zA-Z0-9]/g, "");
}
function removeSymbols(string){
    return string.replace(/[&\\/\\#,+()$~%.'":*?<>{}]/g, '');
}
</script>
<style lang="scss" scoped>
    .modal-container{
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: var(--modalBackdrop);
        z-index: 10;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: fade-in 0.2s;
        // &.minimize-container{
        //     display: none;
        // }
    }
    .modal-box{
        position: absolute;
        @extend .primary-box;
        box-shadow: var(--defaultShadow);
        margin: auto;
        grid-column: 1;
        grid-row: 1;
        z-index: inherit;
        background-color: var(--bgColor);
        // animation: fade-in 0.2s;
        // resize: both;
        max-height: 100%;
        &.main-modal{
            z-index: 1000;
        }
        &.minimized{
            // display: none;
            animation: minimize 0.2s;
        }
    }

    .modal-header{
        padding: 0.1rem 0.5rem;
        background: var(--bgLight);
        border-bottom: 1px solid var(--borderColor);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: default;
        min-height: 26px;
        .minimize-icon{
            margin-right: .5em
        }
        .modal-title{
            @extend .secondary-font;
            font-size: 1rem;
            padding-top: 0.2rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 440px;
        }

        &.prompt .modal-title{ 
            max-width: 335px;
        }

    }

    .modal-content{
        padding: 1.7rem 2rem;
        padding-top: 1rem;
        min-width: 450px;
        max-height: 95vh;
        max-width: 95vw;
        width: 100%;
        min-width: 500px;
        &.p-0{
            padding: 0;
        }
        &.newTaskGroup{
            min-width: 650px;
        }

        &.prompt{
            min-width: 370px;
        }
        &.addDevice,
        &.addDevicesFromFile,
        &.confirmConfig,
        &.changelog,
        &.vpnForm,
        &.setMonitoringUpdatePeriod,
        &.deviceIntroduction,
        &.exportPeriodBackup,
        &.createNewRole,
        &.addNewReport,
        &.createAlert,
        &.passwordManagement,
        &.addNewRemote,
        &.newTaskGroup,
        &.taskManager,
        &.actionStatus {
            overflow: auto;
        }
        &.addDevice{
            max-width: 600px;
        }

        ::v-deep .table-wrapper{
            overflow: auto;
            max-height: 60vh;
            padding-bottom: 1px;
            margin-bottom: 0;
        }

        ::v-deep .bar-wrapper{
            border: 1px solid var(--borderInput);
        }
    }

    .modal-title{
        @extend .secondary-font;
        font-size: 1.5rem;
    }

    .minimized-list {
        position: fixed;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        bottom: 0;
        z-index: 10;
        right: 2em;
        .minimized-item{
            &.minimized{
                text-transform: none;
                font-size: 1em;
                display: flex;
                line-height: normal;
            }
            a:first-child {
                width: 28ch;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            @extend .secondary-font;
            display: none;
            color: var(--bgColor);
            background-color: var(--mainColor);
            margin: 0 .2em;
            padding: .35em 1em;
            padding-top: 0.5em;
            border-top-left-radius: .35em;
            border-top-right-radius: .35em;
            .close-icon{
                margin-left: .55em;
            }
            &:hover{
                opacity: 0.9;
            }
        }
    }
    @media screen and (max-width: 840px){
        .minimized-list{
            display: none;
        }
    }

.externalFrameModal{
    position: relative;
    overflow: hidden;
}

.reminder-checkbox input{
    vertical-align: -3px;
}

.form-description{
    word-break: break-word;
}

@keyframes minimize {
    0%{ 
        transform: translate(0,0) scaleY(1);
        opacity: 1;
    }
    100%{ 
        transform: translate(5rem, 5rem) scaleY(0.5);
        opacity: 0;
    }
}

</style>