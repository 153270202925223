<template functional>
    <svg xmlns="http://www.w3.org/2000/svg" class="base-icon" v-on="listeners"
    :class="[props.name, data.class, data.staticClass]" 
    :viewBox="$options.viewbox(props.name)" 
    :height="props.size" 
    :width="props.size" 
    :disabled="data.attrs.disabled"
    :title="data.attrs.title"
    @mouseenter="$options.setTooltip($event, data)"
    @mouseleave="$options.removeTooltip()"
    @click="$options.removeTooltip($event, data)"
    >
        <!-- <title v-text="data.attrs.title"></title> -->
        <path v-for="(path, $index) in $options.path(props.name)" :key="$index" :d="path" :stroke="props.color" fill="none" :stroke-width="props.strokeWidth" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>

<script>
import icons from '@/utils/iconDefs'
import {setTooltip, removeTooltip} from '@/directives/tooltip'

export default {
    props: {
        name: {
            required: true,
            type: String
        },
        color: {
            type: String,
            default: 'currentColor'
        },
        size: {
            type: [String, Number],
            default: "16px"
        },
        strokeWidth:{
            type: [String, Number],
            default: "2"
        }
    },
    setTooltip(e, data){
        const text = data.attrs.title;
        if(text && text.trim()){
            const el = e.target;
            setTooltip(el, text);
        }
    },
    removeTooltip(event, data){
        if(window.innerWidth <= 768 && (event && event.target.closest(".base-icon.question"))){
            this.setTooltip(event, data);
        } else {
             removeTooltip();
        }
    },
    path(name){
        return icons[name]?.path
    },
    viewbox(name){
        return icons[name]?.viewbox
    }
}
</script>

<style lang="scss" scoped>
.active{
    color: var(--mainColor);
}
</style>
