var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "field-text-block", class: _vm.getClass }, [
    _c(
      "a",
      { staticClass: "field-hint", class: _vm.getClass },
      [_c("BaseIcon", { attrs: { name: "question", title: _vm.fieldText } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }