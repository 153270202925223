<template>
    <div class="app-logo" @click="goHome()">
        <img v-if="$theme.isBranded" :src="`/assets/${theme.id}/img/logo.svg`" alt="logo">
        <img v-else src="/assets/default/img/logo.svg" alt="logo" width="139" height="28">
        <div v-if="hasLogoText" class="subtext">
            Remote management system
        </div>
    </div>
    
</template>

<script>
export default {
    data() {
        return {
            theme: this?.$theme || {}
        }
    },
    computed:{
        hasLogoText(){
            const brands = ['default', 'celledge'];
            return brands.includes(this.theme.id);
        }
    },
    methods:{
        goHome(){
            location.pathname = '/management';
        }
    }
}
</script>

<style lang="scss">
.app-logo{
    cursor: pointer;
    height: 28px;
    display: flex;
    align-items: center;
    img{
        height: inherit;
    }
    .subtext{
        white-space: nowrap;
        line-height: 0.8;
        font-size: 18px;
        color: var(--mainColor);
        border-left: 2px solid currentColor;
        font-weight: bold;
        font-family: sans-serif;
        margin-left: 10px;
        padding-left: 10px;
    }
}
</style>