var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-field",
      class: {
        "error-input": _vm.errorItem,
        "table-input": _vm.field && _vm.field.component === "TableInput",
      },
    },
    [
      _vm.field
        ? [
            (_vm.field.permission
              ? _vm.$user.can(_vm.field.permission)
              : true) && (_vm.field.depends ? _vm.canShowField : true)
              ? [
                  !_vm.isInlineInput(_vm.field)
                    ? _c("label", [_vm._v(_vm._s(_vm.field.label))])
                    : _vm._e(),
                  _vm.field.component
                    ? [
                        _vm.field.component === "ItemSelect"
                          ? _c("ItemSelect", {
                              attrs: {
                                options: _vm.field.options,
                                value: _vm.form[_vm.field.key],
                                required: _vm.field.required,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    _vm.field.key,
                                    $event
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.field.component === "BaseAlert"
                          ? _c(
                              "BaseAlert",
                              { attrs: { icon: _vm.field.icon } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.field.value(
                                      _vm.form[_vm.field.updateOnKey]
                                    )
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.field.component === "ListInput"
                          ? _c("ListInput", {
                              attrs: {
                                options: _vm.field.options,
                                value: _vm.form[_vm.field.key],
                                required: _vm.field.required,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    _vm.field.key,
                                    $event
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.field.component === "TableInput"
                          ? _c("TableInput", {
                              attrs: {
                                options: _vm.field.options,
                                value: _vm.form[_vm.field.key],
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    _vm.field.key,
                                    $event
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    : _vm.field.options
                    ? [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form[_vm.field.key],
                                expression: "form[field.key]",
                              },
                            ],
                            attrs: { required: _vm.field.required },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.form,
                                  _vm.field.key,
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: { selected: "", disabled: "" },
                                domProps: { value: undefined },
                              },
                              [_vm._v("Select " + _vm._s(_vm.field.label))]
                            ),
                            _vm._l(
                              _vm.selectOptions,
                              function (option, $index) {
                                return _c(
                                  "option",
                                  {
                                    key: $index,
                                    attrs: { title: option.hint },
                                    domProps: {
                                      value: option.hasOwnProperty("value")
                                        ? option.value
                                        : option,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("optionFormatter")(
                                            option.name || option,
                                            _vm.field
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _vm.field.optionDescriptionBlock &&
                        _vm.form[_vm.field.key]
                          ? _c("div", [
                              _c(
                                "small",
                                {
                                  staticClass:
                                    "option-description form-description",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.selectOptions.find(
                                        (option) =>
                                          option.value ==
                                          _vm.form[_vm.field.key]
                                      ).hint
                                    )
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : [
                        _vm.field.type === "password"
                          ? _c(
                              "PassInput",
                              {
                                ref: "passwordInput",
                                class: {
                                  "flex-row v-center": _vm.field.generate,
                                },
                              },
                              [
                                _vm.field.type === "checkbox"
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form[_vm.field.key],
                                          expression: "form[field.key]",
                                        },
                                      ],
                                      class: { "mr-05": _vm.field.generate },
                                      attrs: {
                                        required: _vm.field.required,
                                        minlength: _vm.field.minlength,
                                        maxlength: _vm.field.maxlength,
                                        id: _vm.field.key,
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.form[_vm.field.key]
                                        )
                                          ? _vm._i(
                                              _vm.form[_vm.field.key],
                                              null
                                            ) > -1
                                          : _vm.form[_vm.field.key],
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.form[_vm.field.key],
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.form,
                                                  _vm.field.key,
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.form,
                                                  _vm.field.key,
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.form,
                                              _vm.field.key,
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    })
                                  : _vm.field.type === "radio"
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form[_vm.field.key],
                                          expression: "form[field.key]",
                                        },
                                      ],
                                      class: { "mr-05": _vm.field.generate },
                                      attrs: {
                                        required: _vm.field.required,
                                        minlength: _vm.field.minlength,
                                        maxlength: _vm.field.maxlength,
                                        id: _vm.field.key,
                                        type: "radio",
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.form[_vm.field.key],
                                          null
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$set(
                                            _vm.form,
                                            _vm.field.key,
                                            null
                                          )
                                        },
                                      },
                                    })
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form[_vm.field.key],
                                          expression: "form[field.key]",
                                        },
                                      ],
                                      class: { "mr-05": _vm.field.generate },
                                      attrs: {
                                        required: _vm.field.required,
                                        minlength: _vm.field.minlength,
                                        maxlength: _vm.field.maxlength,
                                        id: _vm.field.key,
                                        type: _vm.field.type,
                                      },
                                      domProps: {
                                        value: _vm.form[_vm.field.key],
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.form,
                                            _vm.field.key,
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                _vm.field.generate
                                  ? [
                                      _c(
                                        "button",
                                        {
                                          attrs: { type: "button" },
                                          on: { click: _vm.generatePassword },
                                        },
                                        [_vm._v("Generate")]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm.field.type === "file"
                          ? _c("FileInput", {
                              attrs: {
                                value: _vm.form[_vm.field.key],
                                required: _vm.field.required,
                                accept: _vm.field.accept,
                                error: _vm.errorItem,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    _vm.field.key,
                                    $event
                                  )
                                },
                              },
                            })
                          : _vm.field.type === "textarea"
                          ? [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form[_vm.field.key],
                                    expression: "form[field.key]",
                                  },
                                ],
                                attrs: {
                                  type: _vm.field.type,
                                  required: _vm.field.required,
                                  minlength: _vm.field.minlength,
                                  maxlength: _vm.field.maxlength,
                                  id: _vm.field.key,
                                  placeholder: _vm.placeholder,
                                },
                                domProps: { value: _vm.form[_vm.field.key] },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.form,
                                      _vm.field.key,
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          : [
                              _vm.field.type === "checkbox"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form[_vm.field.key],
                                        expression: "form[field.key]",
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: _vm.field.mask,
                                        expression: "field.mask",
                                      },
                                    ],
                                    class: _vm.field.class,
                                    attrs: {
                                      required: _vm.field.required,
                                      min: _vm.minValue,
                                      max: _vm.maxValue,
                                      step: _vm.field.step,
                                      minlength: _vm.field.minlength,
                                      maxlength: _vm.field.maxlength,
                                      id: _vm.field.key,
                                      placeholder: _vm.placeholder,
                                      pattern: _vm.field.pattern,
                                      disabled: _vm.field.disabled,
                                      "true-value": _vm.field.trueValue || true,
                                      "false-value":
                                        _vm.field.falseValue || false,
                                      type: "checkbox",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.form[_vm.field.key]
                                      )
                                        ? _vm._i(
                                            _vm.form[_vm.field.key],
                                            null
                                          ) > -1
                                        : _vm._q(
                                            _vm.form[_vm.field.key],
                                            _vm.field.trueValue || true
                                          ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.form[_vm.field.key],
                                          $$el = $event.target,
                                          $$c = $$el.checked
                                            ? _vm.field.trueValue || true
                                            : _vm.field.falseValue || false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.form,
                                                _vm.field.key,
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.form,
                                                _vm.field.key,
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.form, _vm.field.key, $$c)
                                        }
                                      },
                                    },
                                  })
                                : _vm.field.type === "radio"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form[_vm.field.key],
                                        expression: "form[field.key]",
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: _vm.field.mask,
                                        expression: "field.mask",
                                      },
                                    ],
                                    class: _vm.field.class,
                                    attrs: {
                                      required: _vm.field.required,
                                      min: _vm.minValue,
                                      max: _vm.maxValue,
                                      step: _vm.field.step,
                                      minlength: _vm.field.minlength,
                                      maxlength: _vm.field.maxlength,
                                      id: _vm.field.key,
                                      placeholder: _vm.placeholder,
                                      pattern: _vm.field.pattern,
                                      disabled: _vm.field.disabled,
                                      "true-value": _vm.field.trueValue || true,
                                      "false-value":
                                        _vm.field.falseValue || false,
                                      type: "radio",
                                    },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.form[_vm.field.key],
                                        null
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          _vm.field.key,
                                          null
                                        )
                                      },
                                    },
                                  })
                                : _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form[_vm.field.key],
                                        expression: "form[field.key]",
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: _vm.field.mask,
                                        expression: "field.mask",
                                      },
                                    ],
                                    class: _vm.field.class,
                                    attrs: {
                                      required: _vm.field.required,
                                      min: _vm.minValue,
                                      max: _vm.maxValue,
                                      step: _vm.field.step,
                                      minlength: _vm.field.minlength,
                                      maxlength: _vm.field.maxlength,
                                      id: _vm.field.key,
                                      placeholder: _vm.placeholder,
                                      pattern: _vm.field.pattern,
                                      disabled: _vm.field.disabled,
                                      "true-value": _vm.field.trueValue || true,
                                      "false-value":
                                        _vm.field.falseValue || false,
                                      type: _vm.field.type,
                                    },
                                    domProps: {
                                      value: _vm.form[_vm.field.key],
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.form,
                                          _vm.field.key,
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                            ],
                        _vm.isInlineInput(_vm.field)
                          ? _c("label", { attrs: { for: _vm.field.key } }, [
                              _vm._v(_vm._s(_vm.field.label)),
                            ])
                          : _vm._e(),
                      ],
                  _vm.field.hint
                    ? _c("FieldHint", {
                        attrs: {
                          group:
                            _vm.field.hint.group || _vm.formSchema.description,
                          name: _vm.field.hint.name,
                          "target-type": _vm.field.hint.target,
                          content: _vm.field.hint,
                        },
                      })
                    : _vm._e(),
                ]
              : _vm._e(),
          ]
        : _vm._t("default"),
      _vm.errorItem &&
      (_vm.field && _vm.field.permission
        ? _vm.$user.can(_vm.field.permission)
        : true) &&
      (_vm.field && _vm.field.depends ? _vm.canShowField : true)
        ? _c("transition", { attrs: { name: "slide", appear: "" } }, [
            _c(
              "ul",
              { staticClass: "field-error-list" },
              _vm._l(_vm.errorItem, function (error, $index) {
                return _c("li", { key: $index, staticClass: "field-error" }, [
                  _vm._v(_vm._s(error.message)),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }